import { httpRequests } from "../../../helpers/HttpRequests"

export function hotelInformationServices() {
    const { getRequest, putRequest, postRequest, httpErrors } = httpRequests()
    const errors = httpErrors

    async function getHotelInformacion() {
        return await getRequest('/web/hotel')
    }

    async function addHotelInformation(hotelModel) {
        return postRequest('/web/hotel/add', hotelModel)
    }

    async function updateHotelInformation(hotelModel, hotelId) {
        return putRequest(`/web/hotel/update/${hotelId}`, hotelModel)
    }

    return { getHotelInformacion, addHotelInformation, updateHotelInformation, errors }
}