<template>
    <form class="form" @submit.prevent="client.hotelId ? updateHotel() : addHotel()">
        <div class="form__two-fields-form">
            <div class="form__input-form">
                <label class="form__input-label form--required">Name of Property</label>
                <input type="text" placeholder="Name of Property" class="form__input" v-model="hotelModel.nameProperty">

                <div class="form__input-error" v-if="errors.fields.nameProperty">
                    {{ errors.fields.nameProperty[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Property Code</label>
                <input type="text" placeholder="Property Code" class="form__input" v-model="hotelModel.propertyCode">

                <div class="form__input-error" v-if="errors.fields.propertyCode">
                    {{ errors.fields.propertyCode[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Brand</label>
                <input type="text" placeholder="Brand" class="form__input" v-model="hotelModel.brand">

                <div class="form__input-error" v-if="errors.fields.brand">
                    {{ errors.fields.brand[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Type of Property</label>
                <input type="text" placeholder="Type of Property" class="form__input" v-model="hotelModel.typeProperty">

                <div class="form__input-error" v-if="errors.fields.typeProperty">
                    {{ errors.fields.typeProperty[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Address</label>
                <input type="text" placeholder="Address" class="form__input" v-model="hotelModel.address">

                <div class="form__input-error" v-if="errors.fields.address">
                    {{ errors.fields.address[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">City, State, Zip</label>
                <input type="text" placeholder="City, State, Zip" class="form__input" v-model="hotelModel.ctStZp">

                <div class="form__input-error" v-if="errors.fields.ctStZp">
                    {{ errors.fields.ctStZp[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Today´s Date</label>
                <input type="date" placeholder="Today´s Date" class="form__input" v-model="hotelModel.date">

                <div class="form__input-error" v-if="errors.fields.date">
                    {{ errors.fields.date[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Phone Number</label>
                <input type="text" placeholder="Phone Number" class="form__input" v-model="hotelModel.phoneNumber">

                <div class="form__input-error" v-if="errors.fields.phoneNumber">
                    {{ errors.fields.phoneNumber[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Fax Number</label>
                <input type="text" placeholder="Fax Number" class="form__input" v-model="hotelModel.faxNumber">

                <div class="form__input-error" v-if="errors.fields.faxNumber">
                    {{ errors.fields.faxNumber[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Ap Contact Number</label>
                <input type="text" placeholder="Ap Contact Number" class="form__input" v-model="hotelModel.apContact">

                <div class="form__input-error" v-if="errors.fields.apContact">
                    {{ errors.fields.apContact[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Local PD Number</label>
                <input type="text" placeholder="Local PD Number" class="form__input" v-model="hotelModel.localNumber">

                <div class="form__input-error" v-if="errors.fields.localNumber">
                    {{ errors.fields.localNumber[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Website</label>
                <input type="text" placeholder="Website" class="form__input" v-model="hotelModel.website">

                <div class="form__input-error" v-if="errors.fields.website">
                    {{ errors.fields.website[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Admin Email</label>
                <input type="text" placeholder="Admin Email" class="form__input" v-model="hotelModel.adminEmail">

                <div class="form__input-error" v-if="errors.fields.adminEmail">
                    {{ errors.fields.adminEmail[0] }}
                </div>
            </div>

            <div class="form__input-form">
                <label class="form__input-label form--required">Elevators</label>
                <input type="number" placeholder="Total of Elevators" class="form__input"
                    v-model.number="hotelModel.elevators">

                <div class="form__input-error" v-if="errors.fields.elevators">
                    {{ errors.fields.elevators[0] }}
                </div>
            </div>
        </div>

        <div class="form__buttons">
            <button class="button button--success" v-if="client.hotelId && !buttonLoader">
                <img src="@/assets/icons/vuesax/linear/refresh-2.svg" width="15" height="15" alt="">
                Update Hotel
            </button>

            <button class="button button--success" v-if="!client.hotelId && !buttonLoader">
                <img src="@/assets/icons/vuesax/linear/refresh-2.svg" width="15" height="15" alt="">
                Add Hotel
            </button>

            <ButtonTextLoader button-class="button button--success button-text-loader--content-width"
                loader-text="Verifying Information" loader-class="button-text-loader__loader-primary"
                :is-loading="buttonLoader" />
        </div>

    </form>

    <Notifications ref="notification" />

    <!-- Content loader -->
    <ContentLoader :hidden-loader="hiddenLoader" />
</template>

<script setup>
import { reactive, onMounted, ref } from 'vue'
import { hotelInformationServices } from '../Services/HotelInformacionServices.js'
import Notifications from '../../../common/Notifications.vue'
import { useClientStore } from "@/stores/ClientStore.js"
import { usePanicAlertStore } from "@/stores/PanicAlertStore.js"
import { sweetAlerts } from '../../../helpers/SweetAlert.js'
import { useRouter } from 'vue-router'
import ButtonTextLoader from '../../../common/ButtonTextLoader.vue'
import ContentLoader from '@/common/ContentLoader.vue'

const { getHotelInformacion, addHotelInformation, updateHotelInformation, errors } = hotelInformationServices()
const { joinPanicAlertEvent } = usePanicAlertStore()
const { sweetSuccess } = sweetAlerts()
const { client } = useClientStore()
const notification = ref(null)
const router = useRouter()
const buttonLoader = ref(false)
const hiddenLoader = ref(false)
const hotelModel = reactive({
    nameProperty: '',
    propertyCode: '',
    brand: '',
    typeProperty: '',
    address: '',
    ctStZp: '',
    date: '',
    phoneNumber: '',
    faxNumber: '',
    apContact: '',
    localNumber: '',
    website: '',
    adminEmail: '',
    elevators: ''
})

onMounted(async () => {
    const response = await getHotelInformacion()

    // * Hidden content loader
    hiddenLoader.value = true

    if (!response.hasErrors) {
        const hotel = response.data

        hotelModel.nameProperty = hotel.name_property
        hotelModel.propertyCode = hotel.property_code
        hotelModel.brand = hotel.brand
        hotelModel.typeProperty = hotel.type_property
        hotelModel.address = hotel.address
        hotelModel.ctStZp = hotel.ct_st_zp
        hotelModel.date = hotel.date
        hotelModel.phoneNumber = hotel.phone_number
        hotelModel.faxNumber = hotel.fax_number
        hotelModel.apContact = hotel.ap_contact
        hotelModel.localNumber = hotel.local_number
        hotelModel.website = hotel.website
        hotelModel.adminEmail = hotel.admin_email
        hotelModel.elevators = hotel.elevators
    } else {
        notification.value.sendNotification('error', 'Error', response.message)
    }
});

async function addHotel() {
    // * Show button loader
    buttonLoader.value = true

    const response = await addHotelInformation(hotelModel)

    if (!response.hasErrors) {
        const hotel = response.data.data

        client.hotelId = hotel.id;
        client.hotel = hotel.name_property
        client.hotelAddress = hotel.address

        // * Join into panic alert event
        joinPanicAlertEvent()

        sweetSuccess('Hotel Information Added.')

        return router.push('/hotel_setup')
    } else {
        buttonLoader.value = false
        notification.value.sendNotification('error', 'Error', response.message)
    }
}

async function updateHotel() {
    // * Show button loader
    buttonLoader.value = true

    const response = await updateHotelInformation(hotelModel, client.hotelId)

    if (!response.hasErrors) {
        const hotel = response.data.data

        client.hotelId = hotel.id;
        client.hotel = hotel.name_property
        client.hotelAddress = hotel.address

        sweetSuccess('Hotel Information Updated.')

        return router.push('/hotel_setup')
    } else {
        buttonLoader.value = false
        notification.value.sendNotification('error', 'Error', response.message)

        return console.error(`Error updating hotel: ${response.message}`)
    }
}
</script>